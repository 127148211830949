.listenpage {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.bandcamp-embeds {
  margin: 0 70px;
}

iframe {
  border: 0px;
}

@media screen and (max-width: 415px) {
  iframe {
    height: 410px;
  }
}

@media screen and (min-width: 415px) and (max-width: 1100px) {
  iframe {
    height: 385px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1670px) {
  iframe {
    width: 300px;
  }
}
