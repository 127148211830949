.video-spacing {
  margin-bottom: 40px;
}

@media screen and (max-width: 415px) {
  section {
    padding: 20px;
  }

  .videos {
    width: 90%;
  }
}

@media screen and (min-width: 415px) and (max-width: 1000px) {
  section {
    padding: 30px;
  }

  .videos {
    width: 90%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  section {
    padding: 40px;
  }

  .videos {
    width: 80%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  section {
    padding: 50px;
  }

  .videos {
    width: 65%;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1670px) {
  section {
    padding: 50px;
  }

  .videos {
    width: 60%;
  }
}
