div {
  font-size: 22px;
  padding: 0;
  /* color: #f81a17; */
  color: white;
  font-family: "Fugue", verdana;
  text-transform: uppercase;
  text-align: center;
}

img {
  margin: 30px auto;
  width: 90%;
  max-width: 700px;
}

img:hover {
  /* -moz-box-shadow: 0 0 1000px #f81a17;
  -webkit-box-shadow: 0 0 1000px #f81a17;
  box-shadow: 0 0 1000px #f81a17; */
  -moz-box-shadow: 0 0 1000px white;
  -webkit-box-shadow: 0 0 1000px white;
  box-shadow: 0 0 1000px white;
}

.landingpage-video {
  position: absolute;
  width: "100%";
  height: "100%";
  left: 50%;
  top: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

#video {
  position: fixed;
  z-index: -1;
  width: 100%;
}

@media screen and (max-width: 415px) {
  img {
    width: 100%;
  }
}
