* {
  box-sizing: border-box;
  margin: 0px;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  color: #fff;
  /* background: linear-gradient(-45deg, pink, #ffdbe2, lightblue, skyblue); */
  background: black;
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
